export const fmg: Translation = {
  title: 'Интернет-витрина «Фуд Маркет Груп»',
  task: 'создать интернет-витрину для распространителя импортных продуктов питания.',
  intro: 'В студии разработали привлекательный сайт с возможностями интернет-витрины и блога.',
  main: 'Главная страница',
  delights: 'На главной показываем заморские вкусности.',
  bigScreen: 'Главная на большом экране',
  flexibleComposition: 'Композиция подстраивается под размер экрана',
  catalog: 'Раскладка каталога',
  siteOrientation: 'Сайт ориентирован как на потребителей товаров, так и на потенциальных бизнес-партнеров. Карта представительств, созданная на основе карт Гугл, показывает покрытие торговой сетью территории Украины и приглашает к сотрудничеству дистрибьюторов.',
  representativesMap: 'Карта представительств',
  archivedIn: 'Работа сдана в архив в 2015 году.',
};
