export const peopleinbusiness: Translation = {
  title: 'Сайт компании «People in Business»',
  task: 'создать презентационный сайт и блог консалтинговой компании.',
  fancySite: 'Вдохновившись свежими трендами, мы решили сделать интерактивный сайт с живым содержимым и современной графикой.',
  metaphor: 'Сайт построен на метафоре маяка. Компания «Пипл ин бизнес» служит маяком для увязших в бурном море плохих бизнес-процессов и потерявших надежду на своевременное возвращение к родным берегам.',
  main: 'Главная страница',
  presentationalLanding: 'Презентационный лендинг',
  lighthouse: 'Маяк в логотипе переходит на виджет заказа при прокрутке, причем начинает излучать не только в видимом диапазоне, но и в радиоволновом. Из правого нижнего угла футера исходит путеводное сияние.',
  metamorphose: 'Метаморфозы маяка',
  footerLighthouse: 'Маяк в футере',
  polymorphicLighthouse: 'Многоликий маяк',
  goodMobile: 'Иконка мобильного меню тоже перекликается с силуэтом маяка. Кстати, сайт отлично подстроится даже под самый крошечный экран смартфона.',
  mobileVersion: 'Мобильная версия',
  solutionsScreen: 'Экран «Решения» рассказывает о трёх основных услугах, предоставляемых компанией. На каждом слайде рассказано, что получит клиент после выполнения работ и каким способом будет решена задача. Слайды можно листать стрелками или вертикальной прокруткой.',
  solutions: 'Решения',
  mainPic: 'Главная иллюстрация изображает команду деловых людей. Экран «О нас» описывает подходы и раскрывает миссию компании.',
  peopleInBusiness: 'Деловые люди',
  archivedIn: 'Работа сдана в архив в 2016 году.',
};
