export const justprivat: Translation = {
  title: 'Сайт фирмы «Юстприват»',
  task: 'создать презентационный сайт юридической фирмы.',
  intro: 'Мы разработали структурированный сайт, рассказывающий о компании, её сотрудниках и об услугах для разных групп клиентов.',
  main: 'Главная страница',
  serviceDescription: 'Описание услуг на главной',
  mobileMain: 'Главная на мобильном устройстве',
  adaptiveSite: 'Сайт удобно просматривать и с компьютеров, и с мобильных устройств.',
  twoLanguages: 'Сайт выходит на двух языках — русском и украинском. Панель администратора позволяет настраивать переводы содержимого на любых языках.',
  uaVersion: 'Украинская версия сайта',
  blogDescribesCases: 'В блоге сотрудники фирмы публикуют ответы на популярные вопросы и актуальные случаи из юридической практики.',
  cases: 'Блог с актуальными случаями из юридической практики',
  archivedIn: 'Работа сдана в архив в 2016 году.',
};
