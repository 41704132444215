export const justprivat: Translation = {
  title: 'Сайт фірми «Юстпріват»',
  task: 'створити презентаційний сайт юрідичної фірми.',
  intro: 'Ми розробили структурований сайт, що розповідає про компанію, її співробітників, а також про послуги для різних груп клієнтів.',
  main: 'Головна сторінка',
  serviceDescription: 'Опис послуг на головній',
  mobileMain: 'Головна на мобільному пристрої',
  adaptiveSite: 'Сайт зручно проглядати як з комп’ютерів, так і з мобільних пристроїв.',
  twoLanguages: 'Сайт видають двома мовами — російською та українською. Панель адміністратора дозволяє налаштувати переклад вмісту будь-якою мовою світу.',
  uaVersion: 'Українська версія сайту',
  blogDescribesCases: 'В блозі співробітники фірми публікують відповіді на популярні питання й актуальні випадки з юридичної практики.',
  cases: 'Блог з актуальними випадками з юридичної практики',
  archivedIn: 'Роботу здано до архіва у 2016 році.',
};
