export const whoshoe: Translation = {
  title: 'Online shoes store “Who shoe?”',
  task: 'create a commercial Internet site for the shoe store.',
  intro: 'Perebor team has developed a trademark, a logo and a website for a cheap shoes shop. The name "Who shoe?", that was immediately accepted by the customer, has determined the humorous style of interface interaction with the user.',
  logo: 'Logo',
  trademarkAndLogo: 'Perebor team has developed the trademark and the logo.',
  bluebirdOnBoot: 'A bluebird on a boot',
  menuLine: 'Menu bar',
  menuLineIsSewed: 'The menu line is decorated using the sewing machine.',
  moneyUnderTheBoot: 'Money under the boot',
  moneyMoneyMoney: 'Economy shoes allow the owner to waste money.',
  suspicious404: 'Page 404 suspects too curious visitors of being black geologists, and the server error page curses the impermanence of the world.',
  lookingForSomeOil: 'I see. Looking for some oil?',
  angry500: 'Cursed is the day I got behind the wheel of this vacuum cleaner!',
  infiniteScroll: 'The catalog has an infinite list of products. On the left there is an intuitive block of filters, which follows the viewport on scrolling.',
  filterBlock: 'The block with filters',
  nonPreciseSize: 'Inaccurate size',
  nonPreciseSizeForGreedy: 'For those ready to buy shoes of a smaller size, only would they shine, we have prepared the option called “inaccurate size”',
  floatingBlock: 'Clipped block',
  filterBlockFloats: 'The filter block is clipped to the viewport.',
  shoesNeedRest: 'Sometimes shoes take a little rest.',
  shoesHide: 'Shoes are hiding',
  adaptiveSite: 'The site works correctly on both a small screen of the smartphone and a designer’s monitor. Unimportant stuff is getting hidden. By the way, all the sliders and drag-and-drop are adapted to the gestures of the touchscreen.',
  onDesktop: 'On a large monitor',
  onSmartphone: 'On a smartphone',
  adaptiveBanners: 'Banners on the main get composed into a beautiful grid depending on the screen resolution.',
  sizeChoice: 'In the product card, customers choose the size of the shoe using the slider, which shows the approximate foot size in centimeters. Just in case, we’ve added the entire size table in the assistant’s pop-up window.',
  productCard: 'Product card',
  similarProducts: 'Under the card of the goods we show a list of shoes of the same type, approximately similar in price.',
  imageGallery: 'Photo Gallery',
  highQualityImages: 'Quality photos in the gallery allow you to view the shoes close-up.',
  wholesale: 'In the store for small wholesale (with a purchase of two or five pairs) discounts apply.',
  twoAndMore: 'From two pairs',
  fiveAndMore: 'From five pairs',
  theLargerTheBetter: 'The bigger the order, the more discounts.',
  cartCheckout: 'In the basket we suggest the buyer to reconsider the order and pay for it on the same page.',
  cart: 'Cart',
  checkoutForm: 'Order Form',
  checkoutHints: 'Polite and clear tips on the order form. The buyer immediately learns how the store uses his personal data.',
  phoneNumber: 'Phone number',
  personalInfo: 'Personal information',
  shippingTypes: 'Delivery methods',
  shippingHints: 'Short explanations are given for the methods of delivery and payment.',
  paymentTypes: 'Payment methods',
  paymentTypeHints: 'Explanations to the selected method',
  addressShipping: 'Delivery by courier service is paid separately.',
  registration: 'We will register users who agree to create a profile. We’ll even generate a password for them if they do not want to invent it.',
  inventPassword: 'Create a password',
  generatedPassword: 'The password will be generated from several easily remembered English words. This is much more reliable and simpler than incomprehensible combinations with numbers and punctuation marks.',
  loginWidget: 'You use the widget in the upper right corner to log in. For the brave there is a button “show password”',
  enter: 'Login',
  forTheBrave: 'Also an option for the brave:',
  showPassword: 'Show Password',
  voila: 'Voila!',
  thanks: 'Thank you!',
  archivedIn: 'The work is archived in 2014.',
};
