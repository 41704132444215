export const peopleinbusiness: Translation = {
  title: 'Сайт компанії «People in Business»',
  task: 'створити презентаційний сайт і блог консалтингової компанії.',
  fancySite: 'Натхненні свіжими трендами, ми вирішили зробити інтерактивний сайт з живим контентом і сучасною графікою.',
  metaphor: 'Сайт збудований на метафорі маяка. Компанія «Піпл ін бізнес» служить маяком для загрузлих у бурхливому морі поганих бізнес-процесів та позбавлених надії на своєчасне повернення до рідних берегів.',
  main: 'Головна сторінка',
  presentationalLanding: 'Презентаційний лендінг',
  lighthouse: 'Маяк в логотипі пересувається на віджет замовлення при прокручуванні, причому починає випромінювати не тільки у видимому діапазоні, а ще й у радіохвильовому. З правого нижнього кута футера виходить провідне сяйво.',
  metamorphose: 'Метаморфози маяка',
  footerLighthouse: 'Маяк в футере',
  polymorphicLighthouse: 'Лейтмотив маяка',
  goodMobile: 'Іконка мобільного меню також римується до обрису маяка. До речі, сайт відмінно підлаштується навіть під найменший екран смартфона.',
  mobileVersion: 'Мобільна версія',
  solutionsScreen: 'Екран «Рішення» розповідає про три основні послуги, що їх надає компанія. На кожному слайді описано, що отримає клієнт після виконання робіт і в який спосіб буде вирішено задачу. Слайди можна гортати стрілками або вертикальною прокруткою.',
  solutions: 'Рішення',
  mainPic: 'Головна ілюстрація зображає команду ділових людей. Екран «Про нас» описує підходи та розкриває місію компанії.',
  peopleInBusiness: 'Ділові люди',
  archivedIn: 'Роботу здано до архіва у 2016 році.',
};
